<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page"
				:pagination="table.pagination"
			/>
		</section>
	</div>
</template>

<script>
	import ClientsTableSample from "@/components/ClientsTableSample";
	import CardComponent from "@/components/CardComponent";
	import RemoveModal from "@/components/RemoveModal";
	import { BankListService as Service } from "@/services";

	export default {
		name: "BankList",
		components: {
			CardComponent,
			ClientsTableSample,
		},
		data() {
			return {
				table: {
					page: {
						title: "Banka Listesi",
						icon: "bank",
						actions: [
							{
								title: "Banka Oluştur",
								type: "success",
								icon: "plus",
								action: () => {
									this.$router.push({
										name: "bankListCreateOrUpdate",
									});
								},
								perm: "Bank.Create",
							},
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData();
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "list",
						columns: [
							{
								field: "uuid",
								label: "ID",
								sortable: true,
								width: "100px"
							},
							{
								field: "name",
								label: "Banka Adı",
								sortable: true,
								width: "100px"
							},
							{
								field: "code",
								label: "Banka Kodu",
								sortable: true,
								width: "100px"
							},
							{
								field: "logo",
								label: "Logo",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`<img width="70" src="${val.logo}" :alt="${val.name}"/>`,
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								sortable: false,
								width: "100px",
								actions: [
									{
										title: "Düzenle",
										type: "success",
										column: "row",
										perm: "Bank.Edit",
										action: (data) =>
											this.$router.push({
												name: "bankListCreateOrUpdate",
												params: { id: data.uuid },
											}),
									},
									{
										column: "row",
										title: "Sil",
										type: "danger",
										perm: "Bank.Delete",
										action: (data) => {
											this.openModal({
												component: RemoveModal,
												props: {
													id: data.uuid,
													name: data.name,
													service: data.service,
													type: "Banka",
												},
												close: this.$refs.table
													.loadAsyncData,
											});
										},
									},
								],
							},
						],
					},
					pagination: {
						paginate: 50,
						page: 1,
						sortField: "name",
						sortOrder: "desc",
						defaultSortOrder: "desc",
					},
					filter: [
						{
							type: "input",
							field: "name",
							name: "Banka Adı",
						},
					],
				},
			};
		},
	};
</script>
